import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
    Row,
    Col,
    Container,
} from 'react-bootstrap';

import {
    TitlePage,
    SectionParagraphWithBackground,
    // SectionParagraphWithBackgroundV2,
    Footer,
    TestimonyCard,
} from '../../components';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Home = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [testimonies, setTestimonies] = useState([]);
    useEffect(() => {
        const fetchTestimoniesData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/quatreasinfo/api/testimonies/active/`);
                setTestimonies(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTestimoniesData();
    }, []);

    return (
        <div>
            <TitlePage title={"Accueil"} />
            <SectionParagraphWithBackground
                image="images/fond-acceuil.jpg"
                title1="TONGASOA"
                title2="RÊVEZ, NOUS RÉALISONS"
                paragraph={["Votre partenaire de confiance pour tout projets de construction de bâtiment et de piscine sur mesure.",
                    " Chez 4A’S Group, où vos rêves prennent vie. Alliant expertise et dévouement, nous nous engageons à être bien plus qu'une simple entreprise de construction et de conception de piscines ; nous sommes votre partenaire de confiance dans la réalisation de vos projets les plus ambitieux."]}
            />
            <Row className="custom-gallery-row">
                <Col className="custom-gallery-col" id="col1" style={{ marginRight: '-7px', marginLeft: '-7px' }}>
                    <div className="content">
                        <h4 className="custom-gallery-h4" style={{ display: 'block', color: '#fff' }}>
                            <strong>4A’S : Vos murs, votre cœur</strong>
                        </h4>
                        <p className="custom-gallery-description" style={{ display: 'block' }}>
                            Confiez-nous vos rêves et aspirations, et laissez-nous façonner un espace où votre cœur trouvera sa place.
                            Grâce à notre engagement envers l'excellence et notre passion pour la construction,
                            chaque mur devient bien plus qu'une simple structure : il incarne le symbole de votre foyer,
                            de votre famille, et de tout ce qui vous est cher. Ensemble,
                            nous créerons un lieu où vous pourrez vraiment vous épanouir.
                            <br /><br/>
                            <Link className="text-decoration-none" to={`/contact`}>
                                <span className="custom-home-a">Plus d'information</span>
                            </Link>
                        </p>
                    </div>
                    <img className="custom-gallery-img" src="images/building.jpg" alt="Devis bâtiment" style={{ width: '110%' }} />
                </Col>
                <Col className="col custom-gallery-col" id="col2" style={{ marginRight: '-7px', marginLeft: '-7px' }}>
                    <div className="text-start d-xl-flex justify-content-xl-start content">
                        <h4 className="custom-gallery-h4" style={{ display: 'block', color: '#fff' }}>
                            <strong>Pensez piscine ? pensez 4’AS</strong>
                        </h4>
                        <p className="custom-gallery-description">
                            Avec plus de 50 piscines construites depuis notre création en 2020, 
                            tant pour des établissements hôteliers que des propriétés privées, 
                            nous sommes votre choix de confiance pour une construction durable. 
                            Faites le bon choix en nous confiant la réalisation de la vôtre.
                            <br /><br/>
                            <Link className="text-decoration-none" to={`/contact`}>
                                <span className="custom-home-a">Plus d'information</span>
                            </Link>
                        </p>
                    </div>
                    <img className="custom-gallery-img" src="images/pool.png" alt="Devis piscine" style={{ width: '110%' }} />
                </Col>
            </Row>
            {/* <SectionParagraphWithBackgroundV2
                image="images/reve.png"
                title="REVEZ ET NOUS REALISONS"
                paragraph={"Nous nous engageons à transformer les aspirations de nos clients en réalités tangibles. À travers une collaboration étroite et une compréhension approfondie de vos visions, nous mettons tout en œuvre pour concrétiser vos rêves. Nous sommes là à chaque étape du chemin pour transformer vos idées en une réalité exceptionnelle."}
            /> */}
            <Container fluid>
                <InfiniteScroll
                    dataLength={testimonies.length}
                    loader={<TestimonyCard isLoading />}
                    style={{ overflowX: 'hidden' }}
                >
                    <Row className='mx-sm-3'>
                        <Col xs={12}>
                            <h2 className='custom-home-h2 pt-2'>TEMOINAGE</h2>
                            <hr />
                            <p className="custom-p-V2 pt-0 pb-2">Découvrez les retours de nos clients sur leur expérience avec 4A’S. Leurs témoignages sont le reflet concret de notre engagement indéfectible envers la satisfaction client et la qualité exceptionnelle de notre travail. Explorez comment nous avons transformé leurs rêves en réalité et comment nous avons dépassé leurs attentes, chaque témoignage étant une affirmation de notre dévouement à offrir des solutions sur mesure et un service exceptionnel à chaque étape du processus</p>
                        </Col>
                        <Container className='mt-5'>
                            <Row>
                                {testimonies.map((testimony) => (
                                    <TestimonyCard isLoading={isLoading} key={testimony.id} nameClient={testimony.name} imageClient={testimony.photo_profile} imageCover={testimony.image} textClient={testimony.text} />
                                ))}
                            </Row>
                        </Container>
                    </Row>
                </InfiniteScroll>
            </Container>
            <Footer />
        </div>
    );
}

export default Home;
