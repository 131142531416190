import React from 'react';
import ContentLoader from 'react-content-loader';

const GalleryCardPoolSkeletonLoader = () => (
  <ContentLoader
    speed={2}
    width={350}
    height={400}
    viewBox="0 0 350 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="1" y="1" rx="0" ry="0" width="345" height="345" />
    {/* <rect x="1" y="270" rx="3" ry="3" width="150" height="15" />
    <rect x="5" y="295" rx="3" ry="3" width="200" height="10" />
    <rect x="5" y="315" rx="3" ry="3" width="220" height="15" />
    <rect x="5" y="340" rx="3" ry="3" width="100" height="10" />
    <rect x="1" y="360" rx="3" ry="3" width="150" height="15" />
    <rect x="1" y="385" rx="3" ry="3" width="90" height="10" /> */}
  </ContentLoader>
);

export default GalleryCardPoolSkeletonLoader;
