import React, { useState } from "react";
// import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import GalleryCardBuildingSkeletonLoader from "./GalleryCardBuildingSkeletonLoader";

const generateSkeletonColumns = (count) => {
    const skeletonColumns = [];
    for (let i = 0; i < count; i++) {
        skeletonColumns.push(
            <Col key={i} sm={3} xs={12} className="d-flex justify-content-center">
                <div className="bg-white text-center">
                    <GalleryCardBuildingSkeletonLoader />
                </div>
            </Col>
        );
    }
    return skeletonColumns;
};


const GalleryCardBuilding = ({ galleries, isLoading, index, idBuilding, imageBuilding, nameBuilding, priceBuilding, descriptionBuilding }) => {

    const [modal, setModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(index);
    const [currentIdIndex, setCurrentIdIndex] = useState(idBuilding);
    const [currentImageIndex, setCurrentImageIndex] = useState(imageBuilding);
    const [currentNameIndex, setCurrentNameIndex] = useState(nameBuilding);
    const [currentPriceIndex, setCurrentPriceIndex] = useState(priceBuilding);
    const [currentDescriptionIndex, setCurrentDescriptionIndex] = useState(descriptionBuilding);

    const toggleModal = (indexLocal) => {
        setModal(true);
        setCurrentIndex(indexLocal);
        setCurrentIdIndex(galleries[indexLocal].id);
        setCurrentImageIndex(galleries[indexLocal].image);
        setCurrentNameIndex(galleries[indexLocal].name);
        setCurrentPriceIndex(galleries[indexLocal].price);
        setCurrentDescriptionIndex(galleries[indexLocal].description);
    };

    const closeModal = () => {
        setModal(false);
    }

    const nextImage = () => {
        if (currentIndex < galleries.length - 1) {
            toggleModal(currentIndex + 1);
        }
    };

    const prevImage = () => {
        if (currentIndex > 0) {
            toggleModal(currentIndex - 1);
        }
    };

    const redirectToPage = (id) => {
        window.location.href = `/bâtiment/devis/${encodeURIComponent(id)}`;
        setModal(!modal);
    };

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    if (isLoading) {
        return <Row>{generateSkeletonColumns(4)}</Row>;
    }

    return (
        <>
            <div key={idBuilding} id={idBuilding} class="col-md-3 px-0 gallery-bloc">
                <div class="image-container"><img src={imageBuilding} alt={nameBuilding} />
                    <div class="overlay">
                        <div onClick={() => toggleModal(currentIndex)} class="icon">
                            <svg class="bi bi-plus-circle" xmlns="http://www.w3.org/2000/svg" width="0.8em" height="0.8em" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                            </svg>
                        </div>
                        <div class="inner-square">
                            <div class="text-center text-block1">
                                <p>Une de nos réalisations</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modal && (
                <div className="popup">
                    <div className="sous-popup">
                        <img src={currentImageIndex} alt={currentNameIndex} />
                        <button className="order-btn" onClick={() => redirectToPage(currentIdIndex)}>Commandez</button>
                    </div>
                    <div className="text-container">
                        <div className="text-block">
                            <h6 className="custom-title-popup">{currentNameIndex} <small className="custom-gallery-price-2">( {formatPrice(currentPriceIndex)} AR TTC )</small></h6>
                            <p className="custom-p-text-block">
                                {currentDescriptionIndex}
                            </p>
                        </div>
                    </div>
                    <span className="prev-btn" onClick={prevImage}>&#10094;</span>
                    <span className="next-btn" onClick={nextImage}>&#10095;</span>
                    <span className="close-btn" onClick={closeModal}>&times;</span>
                </div>
            )}
        </>
    );
};

export default GalleryCardBuilding;
