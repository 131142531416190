import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import axios from 'axios';

import {
    TitlePage,
    Footer,
    GalleryCardBuilding,
} from '../../components';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const GalleryBuilding = () => {

    const [galleries, setGalleries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/building/api/modelrealize/active/?page=${page}`);
            const data = response.data.results;

            // Check for unique IDs directly in filteredData
            const filteredData = data.filter((newGallery) => !galleries.some((existingGallery) => existingGallery.id === newGallery.id));

            setGalleries(filteredData);
            setPage((prevPage) => prevPage + 1);

        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Stop fetching data if the API returns a 404 error
                setHasMore(false);
            } else {
                console.error('Error fetching data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [page, galleries]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    return (
        <>
            <TitlePage title={"Galerie bâtiment"} />
            <Row style={{ background: 'url("images/fond-50.jpg")' }}>
                <Col className="d-flex align-items-end mt-5 custom-fond-contact">
                    <h1 className='custom-h-contact'>Travaux en extérieur</h1>
                </Col>
            </Row>
            <section className='mx-sm-5 mx-1 mt-3 mb-4' style={{ overflowX: 'hidden' }}>
                <Row className="d-flex">
                    <Col xs={12}>
                        <p className="custom-p-V2">
                            Visualisez nos réalisations étape par étape.
                            Les images montrent nos travaux réceptionnés par nos clients.
                            Les ouvriers sur terrain avec les EPI selon les conformités des HSSES.
                        </p>
                    </Col>
                    <Col xs={12}><hr className='custom-hr' /></Col>
                </Row>
            </section>
            <section className='mx-sm-1 mt-2' style={{ overflowX: 'hidden' }}>
                <InfiniteScroll
                    dataLength={galleries.length}
                    next={() => setPage(page + 1)}
                    hasMore={hasMore}
                    loader={<GalleryCardBuilding isLoading />}
                    style={{ overflowX: 'hidden' }}
                >

                    {galleries.reduce((rows, gallery, index) => {
                        const rowIndex = Math.floor(index / 4);
                        if (!rows[rowIndex]) {
                            rows[rowIndex] = [];
                        }

                        rows[rowIndex].push(
                            <GalleryCardBuilding
                                galleries={galleries}
                                isLoading={isLoading}
                                index={index}
                                idBuilding={gallery.id}
                                imageBuilding={gallery.image}
                                nameBuilding={gallery.name}
                                priceBuilding={gallery.price}
                                descriptionBuilding={gallery.description}
                            />
                        );
                        return rows;
                    }, []).map((row, rowIndex) => (
                        <Row key={rowIndex} className="custom-gallery-row">
                            {row}
                        </Row>
                    ))}
                </InfiniteScroll>
            </section>
            <Footer />
        </>
    )
}

export default GalleryBuilding;